import React, { useState } from 'react';
import './Accordion.css'; // Make sure to create a CSS file to style your accordion

const AccordionSection = ({ label, children, isOpen, onClick }) => (
  <div>
    <button className="accordion-title text-mid" style={{ padding: "30px 0 30px" }} onClick={onClick}>
      {label}
      <span className={`accordion-icon ${isOpen ? 'open' : ''}`}>{isOpen ? '-' : '+'}</span>
    </button>
    {isOpen && <div className="accordion-content">{children}</div>}
  </div>
);

const Accordion = ({ items }) => {
  const [openSection, setOpenSection] = useState("How do I use this test?");

  const handleClick = label => {
    setOpenSection(openSection === label ? null : label);
  };

  return (
    <div className="accordion">
      {items.map(({ label, content }) => (
        <AccordionSection
          key={label}
          isOpen={openSection === label}
          label={label}
          onClick={() => handleClick(label)}
        >
          <div className='text-sm'>{content}</div>
        </AccordionSection>
      ))}
    </div>
  );
};

const App = () => {
  const accordionItems = [
    {
      label: 'How do I use this test?',
      content: <p>Take the 15-20 minutes test to immediately find out your personality type rounded to the nearest 32 types, or pay a small fee to unlock your full results to the nearest 512. You can sign up to save your results with no obligation.
        If you already study Objective Personality then you can use the test as a typing buddy to confirm the types of people you are analyzing.</p>,

    },
    {
      label: 'How was this test developed?',
      content: <p>The foundation of TypeScience Test is substantiated in the pioneering work
        of the psychology luminary, Carl Jung. Elevating the system to greater scientific accuracy, the Objective Personality System (OPS)
        researchers devoted years to discover objectively trackable traits. Through extensive double-blind tests, the OPS
        researchers achieve an impressive ~95% consensus rating independently deducing someone's type from hundreds of options.
        This starkly contrasts with other personality tests, where frameworks are theoretical and results hinge on self-reporting and/or subjective opinions.
        TypeScience pioneers accuracy by integrating A/B testing and double-blind experiments, aligning with OPS. In our commitment to
        objectivity, users can enlist acquaintances who know them well to take the test on their behalf. The results are then averaged,
        adding layers of objectivity for a comprehensive and nuanced assessment. This innovative method ensures a reliable, real-world-backed
        evaluation, setting TypeScience apart from tests anchored solely in theory and self-reporting.
      </p>,
    },
    {
      label: 'How long does the test take?',
      content: <p>Approximately 15-25 minutes. Make sure to take it when you are in a comfortable environment and in a normal state of mind.
      </p>,
    },
    {
      label: 'What sets us apart from other tests?',
      content: <p>What sets TypeScience apart is its foundation in the Objective Personality system, 
        ensuring a unique and scientifically validated approach. While traditional tests may rely on theoretical frameworks, 
        our test is rooted in consistent real-world observations, providing a deeper and more accurate understanding of your p
        ersonality. As far we have have been able to tell, our test is the only one trained on data from people whose types are scientifically known,
        and is also the only test that increases objectivity by allowing other people who know you well to take the test on your behalf.</p>,
    },
    {
      label: 'Can my type change?',
      content: <p>Your personality type is primarily influenced by genetics, representing the "nature" aspect rather than "nurture." 
        While both factors contribute to your personality, genetics plays a substantial role. Understanding your genetic predispositions
        can serve as a valuable shortcut to self-awareness, considering the complexity of the nurture side.
        Observations at scale reveal that individuals with similar OPS types and coins often share physical traits, 
        tattoos, glasses, and even sexual orientations. This pattern extends to identical twins, who frequently 
        exhibit similar personality types.However, acknowledging our weaknesses and blind spots is crucial. 
        We tend to over-rely on our strengths, sometimes applying them in unsuitable situations. 
        Over time, individuals may consciously choose to leverage their less dominant traits, aiming for a
        more balanced approach to life challenges. This strategic shift makes their natural personality types less apparent, 
        as they evolve into more well-rounded individuals.
      .</p>,
    },
    {
      label: 'Who should I date based on my personality type?',
      content: <p>Life encompasses various dimensions, and your preferences for partnerships can be diverse. While OPS provides a framework, it shouldn't be your sole guide. 
      Consider the following insights with an open mind:For a "friends" relationship:Seek someone with more coins in common.For a stronger attraction of opposites:Consider dating 
      someone with more diverse coins.Recognize that managing your strengths is often well within your control, but challenges may arise from your weaknesses.
       Hence, dating someone more different than similar is generally advisable. While extreme differences can lead to a powerful relationship, 
       they may become stressful over time. Exceptionally mature individuals, especially those who've consciously evolved beyond their default personality preferences, 
       can navigate such differences successfully. In the context of a primary life partner, especially given the intimate nature of the relationship, 
       the coin indicating opposite Masculine/Feminine Sensing becomes crucial. This dynamic fosters a yin-yang relationship, with one partner being more assertive and the other more receptive, creating a harmonious balance.
      
      .</p>,
    },
    {
      label: 'Can my type have been influenced by trauma or early childhood?',
      content: <p>Your personality is dynamic, capable of changing in expression, yet your core OPS type remains constant.
      Even in shared experiences, like those between siblings, responses can differ significantly. This divergence often stems 
      from predispositions to be affected by specific events, leading to varied reactions. Our inclination is to craft narratives 
      justifying our present selves based on these experiences, rather than recognizing them as excuses. 
      For instance, a person inclined towards independence and chaos may attribute it to rebelling against a conformist, 
      religious upbringing. Conversely, others from identical circumstances may embrace conformity and religiosity.
      While genuine trauma and early environments can impact behavior, aligning with your natural self tends to yield greater happiness and success.
      .</p>,
    },
    {
      label: 'What job should I have based on my personality type?',
      content: <p>Your personality type significantly influences the types of work you'll find enjoyable and excel in. 
      A general guideline suggests that aligning your career with your coins leads to greater fulfillment and success. 
      For instance, Mind types typically thrive in engineering careers, leveraging their affinity for logical reasoning,
      while Feeling types might find alternative paths more satisfying.Bookmark this site for future updates, as we delve deeper 
      into correlating specific jobs with each coin, providing valuable insights to guide your career choices.
      .</p>,
    },
  ];

  return <Accordion items={accordionItems} />;
};

export default App;
